<template>
  <div class="buyPup">
    <div class="title">确认订单</div>
    <div class="cont">
      <div class="items">
        <div class="tit">套餐选择：</div>
        <div class="itemcont">
          <div class="itemL" v-for="(item, index) in skudata" :key="index" :class="item.checked ? 'on' : ''"
            @click="checkClick(item, index)">
            <div class="pic">
              <viewer :images="skudata[index].img">
                <img :src="item.img" alt="" @click.stop="clickBigShow(skudata[index].img)">
              </viewer>
            </div>
            <div class="textcont">{{ item.title }}</div>
            <div class="price">￥{{ item.price }}</div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="text">收货人：</div>
        <div class="input"><input type="text" v-model="person" placeholder="请输入收货人姓名"></div>
      </div>
      <div class="item">
        <div class="text">手机号码：</div>
        <div class="input"><input type="text" v-model="phone" placeholder="请输入购买者手机号我们尽快联系"></div>
        <!-- <button type="button" class="getcodebtn" v-if="!isGetCode" @click="getCodeClick">获取验证码</button>
        <button type="button" class="getingcodebtn" v-else>{{ geiCodeTime }}s后可重试</button> -->
      </div>
      <div class="item">
        <div class="text">收货地区：</div>
        <div class="input">
          <div class="subtext" @click="showArea">{{ areaText ? areaText : "请选择收货地区" }}</div>
          <van-icon name="arrow-down" size="16px" />
        </div>
      </div>
      <div class="item">
        <div class="text">详细地址：</div>
        <div class="input"><input type="text" v-model="address" placeholder="请输入详细地址"></div>
      </div>
      <div class="item">
        <div class="text">商品数量：</div>
        <div class="input">
          <button type="button" class="manas" :class="changeMath == math ? 'gray' : ''" @click="manasClick"><img
              src="@/assets/img/de_icon3.png" alt=""></button>
          <div class="value">{{ math }}</div>
          <button type="button" class="plus" @click="plusClick"><img src="@/assets/img/de_icon4.png" alt=""></button>
        </div>
      </div>
      <div class="item">
        <div class="text">商品运费：</div>
        <div class="input">{{ freightvalue }}</div>
      </div>
    </div>
    <div class="bottom">
      <div class="allprice">
        应付款：<img src="@/assets/img/de_icon5.png" alt=""><span>{{ totalPrice }}</span>
      </div>
      <button class="buybtn" @click="toBuyClick">立即支付</button>
    </div>

    <van-popup v-model:show="areaShow" position="bottom" round>
      <van-cascader v-model="areaValue" :field-names="fieldNames" active-color="#1989fa" title="选择地区"
        :options="areaList" @close="cancerClick" @finish="okClick" />
    </van-popup>
  </div>
</template>

<script setup>
import { onMounted, ref, toRefs, defineProps, getCurrentInstance } from "vue";
import tokenApi from "@/axios/tokenApi";
// import API from "@/axios";
import { Toast } from "vant";
import { useRouter } from "vue-router";


const router = useRouter();

const props = defineProps({
  areaList: {
    type: Array,
    required: false,
  },
  freight: {
    type: String,
    required: false,
  },
  shareId: {
    type: String,
    required: false,
  },
  skudata: {
    type: Array,
    required: false,
  },
});

const { areaList, freight, shareId, skudata } = toRefs(props);

const $viewerApi = getCurrentInstance().appContext.config.globalProperties.$viewerApi

const person = ref("")
const phone = ref("")
// const isGetCode = ref(false)
// const geiCodeTime = ref(10)
// const phoneCode = ref("")
const address = ref("")
const areaValue = ref("")
const areaText = ref("")
const math = ref(null)
const changeMath = ref(null)
const freightvalue = ref(0)
const fvalue = ref(15)
const totalPrice = ref("")

// const timeer = ref(null)

const userToken = ref("")

const payData = ref(null)

const priceValue = ref("")

const skuId = ref("")

const isArea = ref(false)

// function getCodeClick() {
//   // 获取手机验证码

//   // console.log(phone.value.length)
//   if (phone.value.length == 11) {
//     isGetCode.value = true
//     timeer.value = setInterval(() => {
//       if (geiCodeTime.value > 0) {
//         geiCodeTime.value = geiCodeTime.value - 1
//       }
//     }, 1000)

//     sendSms()

//     setTimeout(() => {
//       clearInterval(timeer.value)
//       isGetCode.value = false;
//       geiCodeTime.value = 10
//     }, 11000)
//   }
// }

function manasClick() {
  if (math.value > changeMath.value) {
    math.value = math.value - changeMath.value
  } else {
    math.value = changeMath.value
  }

  orderSettlement()
}

function plusClick() {
  math.value = math.value + changeMath.value
  orderSettlement()
}

const areaShow = ref(false);

function showArea() {
  areaShow.value = true;
}

const fieldNames = {
  text: 'title',
  value: 'id',
  children: 'children',
};

function okClick(val) {
  areaText.value = val.selectedOptions[0].title + "/" + val.selectedOptions[1].title + "/" + val.selectedOptions[2].title
  areaShow.value = false;

  isArea.value = true

  getFreight()
}

function cancerClick() {
  areaShow.value = false;
}

function getFreight() {
  tokenApi({
    url: "/api/regionFreight",
    method: "post",
    data: {
      userToken: userToken.value,
      region_code: areaValue.value,
      sku_id: skuId.value,
      share_id: shareId.value
    },
  }).then((res) => {
    if (res.data.status == 200) {
      fvalue.value = res.data.data.freight
      orderSettlement()
    } else {
      Toast.fail({
        message: res.data.msg
      });
    }
  });
}

function toBuyClick() {
  tokenApi({
    url: "/api/activityOrderCreate",
    method: "post",
    data: {
      userToken: userToken.value,
      share_id: shareId.value,
      name: person.value,
      phone: phone.value,
      // sms_code: phoneCode.value,
      region_code: areaValue.value,
      address: address.value,
      num: math.value,
      freight: freightvalue.value,
      total_price: totalPrice.value,
      sku_id: skuId.value
    },
  }).then((res) => {
    if (res.data.status == 200) {

      payData.value = res.data.data.jsApiParameters

      if (isWeixin()) {
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady(res.data.data.order_no), false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady(res.data.data.order_no));
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady(res.data.data.order_no));
          }
        } else {
          onBridgeReady(res.data.data.order_no);
        }
      }
    } else {
      Toast.fail({
        message: res.data.msg
      });
    }
  });
}

/**
 * 此方法判断是否是在微信浏览器中
 */
function isWeixin() {
  var WxObj = window.navigator.userAgent.toLowerCase();
  if (WxObj.match(/microMessenger/i) == 'micromessenger') {
    return true;
  } else {
    return false;
  }
}

//支付
function onBridgeReady(orderNo) {
  console.log(orderNo)
  //挂在window注意
  window.WeixinJSBridge.invoke('getBrandWCPayRequest',
    {
      "appId": payData.value.appId,   //公众号ID，由商户传入
      "timeStamp": payData.value.timeStamp,   //时间戳，自1970年以来的秒数
      "nonceStr": payData.value.nonceStr,      //随机串
      "package": payData.value.package,
      "signType": payData.value.signType,     //微信签名方式：
      "paySign": payData.value.paySign //微信签名
    },
    function (res) {
      if (res.err_msg === "get_brand_wcpay_request:ok") {
        //支付成功跳转页面
        // window.location = 'http://www.baidu.com?no=' + orderNo

        router.push({
          name: "payDonePage",
          query: {
            price: totalPrice.value
          }
        });

      } else if (res.err_msg === "get_brand_wcpay_request:fail") {
        //支付失败跳转页面
        // window.location = 'http://www.baidu.com'
      }
    }
  );
}


// function sendSms() {
//   API({
//     url: "/web/sendSms",
//     method: "post",
//     data: {
//       phone: phone.value,
//       type: "eleven"
//     },
//   }).then((res) => {
//     Toast.fail({
//       message: res.data.msg
//     });
//   });
// }

function orderSettlement() {
  let totPrice = ""
  freightvalue.value = Math.round(fvalue.value * math.value * 100) / 100
  totPrice = (priceValue.value * math.value) + freightvalue.value
  totalPrice.value = Math.round(totPrice * 100) / 100

}

function checkClick(item, index) {
  for (const item of skudata.value) {
    if (item.checked == true) {
      item.checked = false
    }
  }

  skudata.value[index].checked = true
  priceValue.value = item.price
  math.value = item.min_num
  changeMath.value = item.min_num
  skuId.value = item.id

  if (isArea.value) {
    getFreight()
  } else {
    orderSettlement()
  }
}

function clickBigShow(item) {
  $viewerApi({ images: [item] })
}

onMounted(() => {
  fvalue.value = freight.value
  userToken.value = window.localStorage.getItem('userToken');
  for (const item of skudata.value) {
    if (item.checked == true) {
      priceValue.value = item.price
      math.value = item.min_num
      changeMath.value = item.min_num
      skuId.value = item.id
    }
  }
  orderSettlement()
})
</script>

<style lang="less" scoped>
.buyPup {
  padding: 20px;
  margin-bottom: 3vh;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
}

.title {
  text-align: center;
  font-size: 30ox;
  font-weight: 600;
  color: #333;
  margin-bottom: 30px;
}

.cont {
  margin-bottom: 30px;
  flex-grow: 1;
  height: 10px;
  overflow-y: auto;

  .item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    height: 50px;

    .text {
      margin-right: 15px;
      color: #050E20;
    }

    .input {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-end;

      .subtext {
        color: #999;
      }

      input {
        text-align: right;
        color: #999;
        width: 100%;
      }

      .manas {
        width: 36px;
        height: 36px;
        margin-right: 15px;
        background: none;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .gray {
        filter: grayscale(200%);
        -webkit-filter: grayscale(200%);
        opacity: 0.2;
        -webkit-opacity: 0.2;
      }

      .plus {
        width: 36px;
        height: 36px;
        margin-left: 15px;
        background: none;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .value {
        text-align: center;
        height: 50px;
        line-height: 50px;
        font-size: 28px;
      }
    }

    .getcodebtn {
      height: 50px;
      line-height: 50px;
      font-size: 28px;
      display: block;
      color: #999;
      border-radius: 6px;
      padding: 0 10px;
      background: @theme-secondary-color;
      color: #fff;
      margin-left: 15px;
    }

    .getingcodebtn {
      height: 50px;
      line-height: 50px;
      font-size: 28px;
      display: block;
      color: #999;
      border-radius: 6px;
      padding: 0 10px;
      background: #dfdfdf;
      color: #999;
      margin-left: 15px;
    }
  }

  .items {
    .tit {
      margin-bottom: 16px;
    }

    .itemcont {
      .itemL {
        display: flex;
        padding: 12px;
        align-items: center;
        background: #F3F3F3;
        margin-bottom: 20px;
        border-radius: 10px;

        .pic {
          width: 84px;
          height: 84px;
          margin-right: 8px;
          border-radius: 10px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: contain;
          }
        }

        .textcont {
          font-size: 24px;
          line-height: 24px;
          flex: 1;
          margin-right: 4px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: #7D818A;
        }

        .price {
          font-size: 24px;
          line-height: 24px;
          color: #7D818A;
        }
      }

      .on {
        border: 1px solid #476FFB;

        .pic {
          border: 1px solid #476FFB;
          width: 82px;
          height: 82px;
        }

        .textcont {
          color: #000;
        }

        .price {
          color: #000;
        }
      }
    }
  }
}

.bottom {
  display: flex;
  align-items: center;
  border-top: 1px solid #dfdfdf;
  padding: 20px 0 0 0;
  justify-content: space-between;

  .allprice {
    margin-right: 20px;
    font-size: 32px;
    color: #050E20;
    display: flex;
    align-items: center;

    img {
      width: 32px;
      height: 32px;
      display: block;
    }

    span {
      color: #FF5F5F;
      font-size: 32px;
    }
  }

  button {
    background: none;
    font-size: 28px;
    height: 66px;
    line-height: 66px;
    border-radius: 10px;
    width: 280px;
    border: 3px solid #476FFB;
    background: #476FFB;
    color: #fff;
  }
}
</style>