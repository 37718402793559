<template>
  <div class="recharge_activity">
    <div class="cont">
      <div class="products">
        <div class="pic">
          <van-swipe class="my-swipe" :autoplay="3000" :loop="false" indicator-color="white">
            <van-swipe-item v-for="(item, index) in infoData.banner_imgs" :key="index">
              <viewer :images="item">
                <van-image width="100%" height="100%" lazy-load :src="item" @click="clickBigShow(item)" />
              </viewer>
            </van-swipe-item>
          </van-swipe>
          <!-- <img :src="infoData.banner_imgs" alt=""> -->
        </div>
        <div class="text">
          <div class="price">￥{{ infoData.price }}<span>￥{{ infoData.original_price }}</span></div>
          <div class="name">{{ infoData.title }}</div>
        </div>
      </div>
      <div class="longpic">
        <div class="item" v-for="(item, index) in infoData.content" :key="index">
          <viewer :images="item">
            <van-image width="100%" height="100%" lazy-load :src="item" @click="clickBigShow(item)" />
          </viewer>
          <!-- <img :src="infoData.content" alt=""> -->
        </div>
      </div>

      <div class="linkcustomer">
        <div class="avatar"><img :src="infoData.bd_avatar ? infoData.bd_avatar : require('@/assets/img/avatar_val.png')"
            alt="">
        </div>
        <div class="link" @click="linkClick"><span>您的专属顾问经理:{{ infoData.bd_nickname }}</span><van-icon
            name="phone-circle" size="20px" color="#2a57fa" /></div>
      </div>
    </div>
    <div class="btns">
      <div class="icon" @click="linkClick"><img src="@/assets/img/de_icon1.png" alt=""></div>
      <button type="button" @click="toOrderList">我的订单</button>
      <button type="button" @click="showBuyPup = true">立即支付</button>
    </div>

    <div class="flowbutton" @click="flowClick"><img src="@/assets/img/fo_icon.png" alt=""></div>

    <van-popup v-model:show="showBuyPup" position="bottom" style="height:80vh" round>
      <buyPup :areaList="infoData.region" :shareId="share_id" :skudata="infoData.sku_list"
        :freight="infoData.default_freight" />
    </van-popup>

  </div>
</template>

<script setup>
import { onMounted, ref, getCurrentInstance } from "vue";
import API from "@/axios";
import tokenApi from "@/axios/tokenApi";
import buyPup from "@/views/doubleEleven/subconpoment"
import { useRouter, useRoute } from "vue-router";
import { Toast } from "vant";
import wx from 'jweixin-js';

const $viewerApi = getCurrentInstance().appContext.config.globalProperties.$viewerApi

const router = useRouter();
const route = useRoute()

const showBuyPup = ref(false)

const userToken = ref("")

const infoData = ref({})

const appId = ref("")

const code = ref("")
const share_id = ref("")
const scope_url = ref('');//微信公众号授权发起地址
// 需要使用的JS接口列表
const jsApiListArr = [
  "updateTimelineShareData",
  "updateAppMessageShareData",
  'checkJsApi',
];

const reload = ref(false)
//获取微信配置并初始化微信jssdk
function getWxConfig(getCode = false) {
  API({
    url: "/web/wxJs",
    method: "post",
    data: {
      url: encodeURIComponent(window.location.href.split("#")[0]),
    },
  }).then((res) => {

    reload.value = true

    if (res.data.status == 200) {
      appId.value = res.data.data.appId;
      scope_url.value = res.data.data.scope_url;

      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: res.data.data.appId, // 必填，公众号的唯一标识
        timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
        signature: res.data.data.signature,// 必填，签名
        jsApiList: jsApiListArr
      });
      wx.checkJsApi({
        jsApiList: jsApiListArr,
        success: function () {
          // console.log('wx.checkJsApi:success',res)
          // 以键值对的形式返回，可用的api值true，不可用为false
          // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
        },
        error: function (res) {
          console.log('wx.checkJsApi:error', res)
        }
      });

      wx.error(function (res) {
        console.log('wx.error', res)
        // Toast.fail({
        //   message: '微信JSSDK信息验证失败！'
        // });

        if (reload.value) {
          location.reload();
          reload.value = false
        }
      });

      //是否需要授权登录
      if (getCode) {
        getwxCode();
      }
    } else {
      Toast.fail({
        message: res.data.msg
      });
    }
  });
}

//微信授权
function getwxCode() {
  let userToken = window.localStorage.getItem('userToken');
  const hrefUrl = window.location.href.split("#")[0]
  if (!userToken) {
    window.location.href = scope_url.value + '?back=' + encodeURIComponent(hrefUrl);
  }
}
//微信登录-》活动详情
function sendCode() {
  API({
    url: "/web/visitorLogin",
    method: "post",
    data: {
      code: code.value
    },
  }).then((res) => {
    if (res.data.status == 200) {
      // console.log(res)
      userToken.value = res.data.data.userToken
      window.localStorage.setItem('userToken', userToken.value);
      getInfo()
    } else {
      Toast.fail({
        message: res.data.msg
      });
    }
  });
}

//活动详情->加载分享组件
function getInfo() {
  tokenApi({
    url: "/api/activityDetail",
    method: "post",
    data: {
      share_id: share_id.value
    },
  }).then((res) => {
    if (res.data.status == 200) {
      infoData.value = res.data.data
      document.title = res.data.data.title

      //加载分享组件
      loadWxShare()

    } else if (res.data.status == 555) {
      // 555是登录失败
      window.localStorage.removeItem('userToken');
      getwxCode();
    } else {
      Toast.fail({
        message: res.data.msg
      });
    }
  });
}

function toOrderList() {
  router.push({
    name: "DoubleElevenOrder"
  });
}

//加载分享组件
function loadWxShare() {

  wx.ready(function () {

    let shareTitle = infoData.value.share_title;
    let shareDesc = infoData.value.share_desc;
    let shareLink = infoData.value.share_url;
    let shareImg = infoData.value.share_img;

    //1.4以上用这个分享给朋友
    wx.updateAppMessageShareData({
      title: shareTitle,
      desc: shareDesc,
      link: shareLink,
      imgUrl: shareImg,
      success: function () {
        // 分享成功可以做相应的数据处理
      },
      fail: function (res) {
        console.log('updateAppMessageShareData.fail', res)
      }
    })

    //1.4以上用这个分享到朋友圈
    wx.updateTimelineShareData({
      title: shareTitle,
      link: shareLink,
      imgUrl: shareImg,
      success: function () {
        // 分享成功可以做相应的数据处理
      },
      fail: function (res) {
        console.log('updateTimelineShareData.fail', res)
      }
    })
  });
}

function linkClick() {
  window.location.href = "tel://" + infoData.value.bd_tel;
}

// 关注
function flowClick() {
  window.location.href = "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzg5MTg1NTc4MQ==#wechat_redirect"
}

function clickBigShow(item) {
  $viewerApi({ images: [item] })
}

onMounted(() => {
  let userTokenCode = window.localStorage.getItem('userToken');

  if (route.query.code) {
    code.value = route.query.code
  } else {
    // code.value = "001WA50008Hg1S1tJb100bQqEq1WA50v"
  }

  if (route.query.share_id) {
    share_id.value = route.query.share_id
  } else {
    // share_id.value = "Zwp9aq"
  }


  if (userTokenCode) {
    // console.log("有userToken",userTokenCode)
    userToken.value = userTokenCode
    getWxConfig();
    getInfo()
  } else {
    // console.log("无userToken")

    if (code.value) {
      //授权回调后,登录并获取活动详情
      // console.log("有code",code.value)
      getWxConfig();
      sendCode()
    } else {
      //无token无code则开始授权
      getWxConfig(true);
    }
  }
})

</script>

<style lang="less" scoped>
.recharge_activity {
  width: 100vw;
  height: 98vh;
  overflow-y: auto;
  display: flex;
  flex-flow: column;
  box-shadow: border-box;
}

.cont {
  flex-grow: 1;
  height: 10px;
  overflow-y: auto;
}

.products {
  overflow: hidden;
  margin-bottom: 20px;

  .pic {
    width: 100%;
    box-sizing: border-box;

    img {
      width: 100%;
      display: block;
    }
  }

  .text {
    background: #fff;
    padding: 20px;
    color: #333;
    font-family: "Regular";

    .price {
      color: red;
      font-size: 50px;
      font-weight: 600;
      margin-bottom: 10px;

      span {
        font-size: 32px;
        text-decoration: line-through;
      }
    }

    .name {
      font-size: 32px;
      font-weight: 600;
      color: #333;
    }
  }
}

.longpic {
  width: 100%;
  box-sizing: border-box;

  .item {
    width: 100%;
  }

  img {
    width: 100%;
    display: block;
  }
}

.btns {
  display: flex;
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px #dfdfdf;

  .icon {
    width: 40px;
    margin: 0 35px 0 16px;

    img {
      width: 100%;
      display: block;
    }
  }

  button {
    background: none;
    font-size: 28px;
    height: 66px;
    line-height: 66px;
    border-radius: 10px;
    margin-right: 20px;
    flex: 1;
    border: 3px solid @theme-secondary-color;
    color: @theme-secondary-color;
  }

  button:last-of-type {
    border: 3px solid @theme-secondary-color;
    background: @theme-secondary-color;
    color: @font-white-color;
    margin-right: 0;
  }
}

.flowbutton {
  position: fixed;
  right: 30px;
  bottom: 200px;
  background: #fff;
  width: 110px;
  height: 110px;
  line-height: 130px;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 20px #dfdfdf;
  color: #333;
  font-size: 30px;
  z-index: 30;

  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.linkcustomer {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 30px 0;

  .avatar {
    width: 120px;
    margin-bottom: 20px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      display: block;
    }
  }

  .link {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 30px;
      line-height: 30px;
      margin-right: 10px;
    }
  }
}

.picshow {
  max-width: 80vw;
  max-height: 80vh;
  overflow: auto;

  img {
    display: block;
  }
}
</style>