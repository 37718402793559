import axios from 'axios'

const tokenApi = axios.create({
	baseUrl:'/api', //请求后端数据的基本地址，自定义
	timeout: 10000                   //请求超时设置，单位ms
})
tokenApi.interceptors.request.use(
  config => {
    let userTokenCode = window.localStorage.getItem('userToken');
    if(userTokenCode){
      config.headers = { 'X-Requested-With': 'XMLHttpRequest', "userToken": userTokenCode }
    }else{
      config.headers = { 'X-Requested-With': 'XMLHttpRequest' }
    }

    return config
  }
)
tokenApi.interceptors.response.use(function (res) {
	if(res.data.status == 100){
		window.location.href = '/saas/login';
	}
    return res;
});
export default tokenApi